//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ExcelTransformer from '@/data/transformer';

export default {
  components: {
    CodeSize: () => import('../CodeSize'),
    ImportCodeList: () => import('./ImportCodeList')
  },
  props: {
    title: {
      required: true,
      type: String
    },
    category: {
      type: Object
    }
  },
  data() {
    return {
      size: '2',
      ecLevel: '1',
      codeList: []
    };
  },
  computed: {
    meta() {
      return {
        size: this.size,
        ecLevel: this.ecLevel
      };
    },
    whiteList: () => ['xls', 'xlsx']
  },
  methods: {
    beforeUpload(file) {
      // Only accepted file .xls .xlxs
      const fileExtend = file.name.split('.').pop();
      if (!this.whiteList.includes(fileExtend)) {
        this.$notification.warning({
          message: this.$t('Click or drag file TYPES to this area to upload', {
            types: '(.xls, .xlxs)'
          })
        });
        return false;
      }

      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$notification.warning({
          message: this.$t('File must smaller than 10MB')
        });
      }
      return isLt2M;
    },
    async handleUploadFile({ file }) {
      try {
        const results = await new ExcelTransformer().parse(file);
        if (Array.isArray(results)) {
          if (results.length === 0) {
            return this.$notification.error({
              message: this.$t('Data is not format!')
            });
          }
          this.codeList = results.reduce((acc, fileItem) => {
            const id = `${acc.length + 1}`;

            let status = 'ready';
            let message = '';
            let codes = this.outputParsing(fileItem.codes);
            let typeList = [];
            let codeType = '';
            const codeName = fileItem?.codes[0]?.infos[0]?.text || '';

            codes.forEach(codeItem => {
              typeList = [...typeList, codeItem.navi_type];
              if (codeItem.errors && codeItem.errors.length > 0) {
                if (status !== 'error') {
                  status = 'error';
                  message = codeItem.errors[0];
                }
              }
            });
            if (typeList.includes('0') && typeList.includes('1')) {
              codeType = 'mix_code';
            } else if (typeList.includes(0)) {
              codeType = 'spot_code';
            } else if (typeList.includes(1)) {
              codeType = 'navi_code';
            }
            const item = {
              id,
              name: fileItem.name.trim(),
              codeName: codeName,
              codeType: codeType,
              status: status,
              message: message,
              codes: codes
            };
            return [...acc, item];
          }, this.codeList);
        }
      } catch (error) {
        this.$notification.error({
          message: this.$t('Data is not format!')
        });
      }
    },
    outputParsing(codes) {
      return codes.map(code => ({
        navi_type: +code.type,
        name: code.infos,
        errors: code.errors,
        points: code.points.map(point => ({
          name: point.infos.map(({ lang, text }) => ({ lang, text })),
          lat: point.lat,
          lon: point.long,
          categories: point.categories.filter(i => !!i).join('|'),
          information: point.infos.map(({ lang, info }) => ({
            lang,
            text: info
          }))
        }))
      }));
    },
    onUpdateCodeList({ filesSuccess, filesError }) {
      const updateResults = this.codeList.map(code => {
        const filePass = filesSuccess.find(item => item.name === code.name);
        const fileError = filesError.find(item => item.name === code.name);
        if (filePass) return { ...code, status: filePass.status };
        if (fileError)
          return {
            ...code,
            status: fileError.status,
            message: fileError.message
          };
        return code;
      });

      this.codeList = updateResults;
    },
    changeSize(value) {
      this.size = value;
    },
    changeEcLevel(value) {
      this.ecLevel = value;
    },
    removeCode(data) {
      this.codeList = this.codeList.filter(item => item.id !== data.id);
    }
  }
};
