var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("code-size", {
        attrs: { size: _vm.size, ecLevel: _vm.ecLevel, codeStatus: "DRAFT" },
        on: { onChangeSize: _vm.changeSize, onChangeEcLevel: _vm.changeEcLevel }
      }),
      _c("a-divider", { attrs: { dashed: "" } }),
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24 } },
            [
              _c(
                "a-upload-dragger",
                {
                  attrs: {
                    name: "files",
                    accept: "",
                    multiple: true,
                    "show-upload-list": false,
                    "custom-request": _vm.handleUploadFile,
                    "before-upload": _vm.beforeUpload
                  }
                },
                [
                  _c("div", { staticClass: "upload-container" }, [
                    _c(
                      "p",
                      { staticClass: "ant-upload-drag-icon upload-icon" },
                      [_c("a-icon", { attrs: { type: "inbox" } })],
                      1
                    ),
                    _c("p", { staticClass: "ant-upload-text upload-text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "Click or drag file TYPES to this area to upload",
                              { types: "(.xls, .xlxs)" }
                            )
                          ) +
                          " "
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24 } },
            [
              _c("import-code-list", {
                attrs: {
                  "code-list": _vm.codeList,
                  meta: _vm.meta,
                  category: _vm.category
                },
                on: {
                  updateCodeList: _vm.onUpdateCodeList,
                  remove: _vm.removeCode
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }